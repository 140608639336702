import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import LinkMui from '@mui/material/Link';
import { Link, useLocation } from "react-router-dom";
import { Grid } from '@mui/material';
import Logo from '../assets/images/logo-white.svg';

const useStyles = makeStyles({
  grid: {
    flexGrow: 1,
    background: "#051E42",
    marginTop: 10,
    '@media (max-width:600px)': {
      paddingBottom: '120px',
    },
  }
});

function Footer() {
  const classes = useStyles();

  const { search } = useLocation();

  return(
    <Grid
      className={classes.grid}
      sx={{  flexDirection: { xs: "column", md: "row"} }}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12} sm={4}>
        <Link to={`/${search}`}>
          <img width='262px' height="68px" src={Logo} alt="logo"/>
        </Link>
      </Grid>
      <Grid item sx={{ maxWidth: {sm: '100%'} }}>
        <Typography color="white" variant="subtitle2">© 2022 Mejortarifaluzygas. All Rights Reserved.</Typography>
      </Grid>
      <Grid item xs="auto" sx={{ marginTop: { xs: '20px', sm: '20px', md: '0px'} }}>
        <LinkMui underline="none">
          <Link to={`/cookies${search}`} target="_blank" style={{ textDecoration: 'none' }}>
            <Typography color="white" variant="subtitle2">Política de cookies</Typography>
          </Link> 
        </LinkMui>
      </Grid>
      <Grid item  xs="auto">
        <LinkMui underline="none">
          <Link to={`/privacidad${search}`} target="_blank" style={{ textDecoration: 'none' }}>
            <Typography color="white" variant="subtitle2">Política de privacidad</Typography>
          </Link>
        </LinkMui>
      </Grid>
      <Grid item xs="auto">
        <LinkMui underline="none">
          <Link to={`/legal${search}`} target="_blank" style={{ textDecoration: 'none' }}>
            <Typography color="white" variant="subtitle2">Información legal</Typography>
          </Link>
        </LinkMui>
      </Grid>
    </Grid>
  )
}

export default Footer;