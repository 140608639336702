import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import ColoredCheckbox from "../checkbox/ColoredCheckbox";
import LinkMui from "@mui/material/Link";
import {Link, useLocation} from "react-router-dom";
import {isPhoneValid} from "../utils/Functions";
import Button from "@mui/material/Button";
import {launchC2C} from "../utils/PostLead";
import Box from "@mui/material/Box";
import CallingDialog from "../dialog/CallingDialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Lottie from "react-lottie-player";
import AnimPhoneRinging from '../assets/anims/ringing-phone.json';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import RepsolLogo from "../assets/images/repsol-logo.svg";
import BysidecarLogoMobile from '../assets/images/bysidecar_logo_header_white.svg';
import { isResponsive, isResponsiveTablet } from '../utils/Functions';

const useStyles = makeStyles(theme => ({
	form: {
		top: '100px',
		width: '360px',
		right: '-360px',
		position: 'fixed',
		zIndex: 1101,
		padding: '0',
		background: 'white',
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '0',
		borderBottomRightRadius: '0',
		borderBottomLeftRadius: '5px',
		transition: 'transform .2s ease',
		boxShadow: '0px 1px 50px #00000029',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
			  borderColor: 'var(--colors-bg-button)',
			},
			'&:hover fieldset': {
				borderColor: 'var(--colors-bg-button)',
			  },
		},
		'&.open': {
			transform: 'translateX(-360px)',
			overflowY: 'overlay',
    		height: '85vh',
			width: '20.5vw'
		},
		[theme.breakpoints.down("md")]: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			transform: 'translateY(100vh)',
			'&.open': {
				transform: 'translateY(0)',
				width: '100%',
				height: '100vh',
				overflowY: 'hidden',
			},
		},
	},
	animPhoneRinging: {
		width: '120px',
		height: '120px',
		margin: '-40px',
		'& path':{
			fill: '#FFFFFF !important',
		},
		[theme.breakpoints.down("md")]: {
			margin: '-40px -30px -40px -40px',
		},
	},
	formBox:{
		[theme.breakpoints.down("md")]: {
			padding:'0 24px 24px 24px'
		},
	},
	submit: {
		textAlign: 'center',
		borderRadius: '5px',
		backgroundColor: '#00809A',
		'&:hover': {
			backgroundColor: 'var(--colors-primary-hover)',
		}
	},
	sticky: {
		zIndex: 1100,
		position: 'fixed',
		top: '50%',
		right: 0,
		height: '110px',
		width: '140px',
		padding: '20px',
		background: '#00809A 0% 0% no-repeat padding-box',

		borderTopLeftRadius: '5px',
		borderTopRightRadius: '0',
		borderBottomRightRadius: '0',
		borderBottomLeftRadius: '5px',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		'&:hover':{
			background: '#1388a0',
		},

		[theme.breakpoints.down("md")]: {
			top: 'auto',
			bottom: 0,
			width: '100%',
			height: 'auto',
			borderTopLeftRadius: '5px',
			borderTopRightRadius: '5px',
			borderBottomRightRadius: '0',
			borderBottomLeftRadius: '0',
		},
	},
	txtPop1: {
		fontSize: '1.375vw',
		[theme.breakpoints.down("md")]: {
			fontSize: '22px',
		},
	},
	txtPop2: {
		fontSize: '2vw',
		[theme.breakpoints.down("md")]: {
			fontSize: '35px',
		},
	},
	txtPop3: {
		fontSize: '5.2vw',

		[theme.breakpoints.down("md")]: {
			fontSize: '98px',
		},
	},
	logoSticky: {
		width: '17vw',
		[theme.breakpoints.down("md")]: {
			width: '270px',
		},
	},
	formBox1:{
		height: '20vh',
		[theme.breakpoints.down("md")]: {
			maxHeight: '10vh',
		},
	}

}));

const StickyForm = () => {
	const [width] = React.useState(window.innerWidth);
	const initialCanvas = ((!isResponsive(width) && !isResponsiveTablet(width)) ? true : false)
	const classes = useStyles();
	const {search} = useLocation();
	const [offcanvas, setOffcanvas] = useState(initialCanvas);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [checked, setChecked] = useState(false);
	const [buttonClicked, setButtonClicked] = useState(false);
	const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);



	const handleCheckboxChange = (event) => {
		setChecked(!checked);
	}

	const handleTextFieldChange = (event) => {
		setPhoneNumber(event.target.value);
	}

	const handleClickOpen = () => {
		setIsOpenCallingDialog(true);
		setTimeout(() => {
			setIsOpenCallingDialog(false);
			handleCloseCallingDialog();
		}, 3000);
	};

	const handleCloseCallingDialog = () => {
		setButtonClicked(false);
	};


	return (
		<>
			<CallingDialog
				open={isOpenCallingDialog}
				onClose={handleCloseCallingDialog}
			/>
			<Button
				className={classes.sticky}
				disableRipple={true}
				onClick={() => {
					setOffcanvas(() => {
						return !offcanvas
					});
				}}
				sx={{
					flexDirection: {sx: 'row', sm: 'column'},
				}}
			>
				<Lottie
					play
					loop
					animationData={AnimPhoneRinging}
					className={classes.animPhoneRinging}
				>
				</Lottie>
				<Typography color="white" variant="subtitle2">
					Llamadme gratis
				</Typography>

			</Button>
			<Box className={{[classes.form]: true, open: offcanvas}}>
				<IconButton
					sx={{
						position: 'absolute',
						top:15,
						right:15,
						color: "#00809A"
					}}
					onClick={() => setOffcanvas(false)}
				>
					<CloseIcon sx={{ fontSize: "2.5rem" }}/>
				</IconButton>
				{/*<Grid xs={12} flex={0} sx={{justifyContent: 'start'}}  padding="24px 24px 20px 24px">
					<Link to={`/${search}`}>
						<img className={classes.logoSticky}src={RepsolLogo} alt="logo" style={{ height: 'auto'}}/>
					</Link>
				</Grid>
				<Grid xs={12} flex={0} justifyContent={'center'} alignItems={'justify'} padding="10px 0" style={{backgroundColor:'var(--colors-primary)'}} marginBottom='30px'>
					<Stack spacing={-1.3} alignItems={'center'}>
						<Link style={{display: 'flex'}} to={`/${search}`}>
							<img src={BysidecarLogoMobile} alt="logo" sx={{display: {xs:'inline-block', sm:'block'}, width: '100px', height: 'auto'}}/>
						</Link>
					</Stack>
				</Grid>*/}
				<Grid container xs={12} className={classes.formBox1} style={{ }}></Grid>
				<Grid className={classes.formBox} padding='0 24px 2.5rem 24px'>
					<Typography variant="h3">
						<span className={classes.txtPop1} style={{ display: 'block'}}>¿Quieres contratar?</span>
						<span className={classes.txtPop2} style={{ display: 'block'}}>Te llamamos ahora</span>
						<span className={classes.txtPop3} style={{ display: 'block', lineHeight: '0.8em', textTransform: 'uppercase'}}>Gratis</span>
					</Typography>

					<Typography style={{fontSize: '1rem', color:'var(--colors-text-dim)'}}>Nuestro equipo de asesores te atenderá de lunes a viernes de 9 a 21h</Typography>
					<TextField inputProps={{style: {fontSize: 14}}} onChange={handleTextFieldChange}
										 sx={{width: '100%', margin: '10px 0'}}
										 label={<Typography variant="body2">Tu teléfono</Typography>}
										 variant="outlined"/>
					<FormControlLabel
						sx={{alignItems: 'start', marginTop: '4px', marginBottom: '4px', fontSize: '12px', color: 'var(--colors-text-dim)'}}
						control={<ColoredCheckbox onChange={handleCheckboxChange}/>}
						label={<Typography variant="subtitle2"sx={{ fontSize: '0.7rem'}}>He leído y acepto la <LinkMui underline="none"
																																								 sx={{textDecoration: 'none'}}><Link
							to={`/privacidad${search}`} target="_blank"
							style={{textDecoration: 'none', color: 'var(--colors-primary)'}}>política de
							privacidad</Link></LinkMui> y consiento el tratamiento de mis datos personales para todas las
							finalidades contenidas en la misma.</Typography>}/>
					{!checked && buttonClicked &&
						<Typography variant="subtitle2" color="red">Debes aceptar la política de privacidad</Typography>}
					{checked && buttonClicked && !isPhoneValid(phoneNumber) &&
						<Typography variant="subtitle2" color="red">El número de teléfono no es válido</Typography>}
					<Button className={classes.submit}
									onClick={() => {
										setButtonClicked(true);
										if (checked && isPhoneValid(phoneNumber)) {
											launchC2C(phoneNumber);
											handleClickOpen();
										}
									}}
									sx={{
										background: '#FF6200 0% 0% no-repeat padding-box',
										borderRadius: '10px',
										marginTop: '1.2rem',
										height: '40px',
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
						<Typography color="white" variant="body3"sx={{ textTransform: 'initial'}}>Llamadme gratis</Typography>
					</Button>
				</Grid>
			</Box>
		</>
	);
}

export default StickyForm;
