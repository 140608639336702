import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import C2CDialog from '../dialog/C2CDialog';

const useStyles = makeStyles({
  button: {
    display: 'none',
    position: 'fixed',
    width: '100%',
    height: '70px',
    bottom: '0',
    textAlign: 'center',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    borderBottomLeftRadius: '5px',
    zIndex: '1110',
    boxShadow: 'inset 2px 2px 10px #FFFFFFBC, 0px 3px 6px #00000029',
    backgroundColor: '#FF6200',
    '&:hover': {
      backgroundColor: '#F39D0A',
    },
    '@media (max-width:600px)': {
      display: 'flex',
    },
  }
});

const StickyC2C = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };


  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Fab className={classes.button} size="medium" aria-label="add" onClick={() => {
        handleClickOpen();
      }}>
        <Stack sx={{ marginTop: '4px' }} spacing={-2}>
          <Typography color="white" variant="subtitle1">TE LLAMAMOS GRATIS</Typography>
        </Stack>
      </Fab>
    </>
  );
}

export default StickyC2C;