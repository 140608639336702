import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import GalanoGrotesqueLight from '../assets/fonts/galano-grotesque/GalanoGrotesque-Light.woff2';
import GalanoGrotesqueBold from '../assets/fonts/galano-grotesque/GalanoGrotesque-Bold.woff2';
import { useNavigate } from 'react-router-dom';
import Helmet from "react-helmet"

let theme = createTheme({
  typography: {
    fontFamily: 'Galano Grotesque, Arial',
    color: '#051E42',
    h4: {
      fontFamily: 'Galano Grotesque Bold, Arial',
    },
    subtitle1: {
      fontSize: '22px',
      fontFamily: 'Galano Grotesque Bold, Arial',
    },
    subtitle2: {
      fontSize: '12px',
    },
    body1: {
      fontSize: '25px',
    },
    body2: {
      fontSize: '18px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Galano Grotesque';
          font-style: normal;
          font-display: swap;
          src: local('Galano Grotesque'), local('Galano Grotesque'), url(${GalanoGrotesqueLight}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Galano Grotesque Bold';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Galano Grotesque Bold'), local('Galano Grotesque Bold'), url(${GalanoGrotesqueBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

function Cookies() {
  let navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>Política de cookies | Mejor tarifa Luz y Gas | Repsol</title>
        <meta name="description" content="Conoce la política de cookies de Repsol. Contrara la mejor tarifa de luz y gas y empieza a ahorrar. Garantizamos el mejor precio"/>
      </Helmet>
      <Stack>
        <Container disableGutters>
          <Header />
        </Container>
        <Container>
          <Typography sx={{ marginTop: '10px' }} onClick={() => navigate(-1)} variant="subtitle1">&lt; Atrás</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="h4">Política de cookies</Typography>
          <Typography sx={{ marginTop: '20px' }} variant="h5">I. MEJORTARIFALUZYGAS.ES INFORMA ACERCA DEL USO DE LAS COOKIES EN SUS PÁGINAS WEB</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Las cookies son archivos que se pueden descargar en su equipo al acceder a una página web. Son herramientas que tienen un papel esencial para la prestación de numerosos servicios de la sociedad de la información. Entre otros, permiten a una página web almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información obtenida, se pueden utilizar para reconocer al usuario y mejorar el servicio ofrecido.</Typography>

          <Typography sx={{ marginTop: '20px' }} variant="h5">II. TIPOS DE COOKIES</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">En la siguiente guía sobre el uso de cookies de la Agencia de Protección de Datos se detallan los distintos tipos de cookies: <a href="http://www.agpd.es/portalwebAGPD/canaldocumentacion/publicaciones/common/Guias/Guia_Cookies.pdf">ver guía</a></Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y trate los datos que se obtengan se pueden distinguir dos tipos: cookies propias y cookies de terceros.</Typography>
          <ul>
            <li>
              <Typography variant="body2">cookies propia: Se envían al dispositivo del usuario desde un equipo o dominio gestionado por el propio editor de la web y desde el que se presta el servicio solicitado por el usuario.</Typography>
            </li>
            <li>
              <Typography variant="body2">cookies de terceros: Se envían al dispositivo del usuario desde un equipo o dominio que no es gestionado por el editor de la web, sino por otra entidad que trata los datos obtenidos a través de las cookies.</Typography>
            </li>
          </ul>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Además, las cookies pueden ser tipificadas:</Typography>
          <ul>
            <li>
              <Typography variant="body2">según el plazo de tiempo que permanecen almacenadas en el ordenador: cookies de sesión o cookies persistentes.</Typography>
            </li>
            <li>
              <Typography variant="body2">según la finalidad: cookies técnicas, cookies de personalización, cookies de análisis, cookies de publicidad comportamental.</Typography>
            </li>
          </ul>
          <Typography sx={{ marginTop: '20px' }} variant="h5">III. COOKIES UTILIZADAS POR MEJORTARIFALUZYGAS.ES</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">A continuación, se identifican las cookies que están siendo utilizadas en este portal, así como su tipología y función:</Typography>
          <ul>
            <li>
              <Typography variant="body2">cookies técnicas: Son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes o las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.</Typography>
            </li>
            <li>
              <Typography variant="body2">cookies de personalización: Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</Typography>
            </li>
            <li>
              <Typography variant="body2">cookies de análisis: Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</Typography>
            </li>
            <li>
              <Typography variant="body2">cookies publicitarias: Son aquéllas que, bien tratadas por nosotros o por terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.</Typography>
            </li>
            <li>
              <Typography variant="body2">cookies de publicidad comportamental: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función de mismo.</Typography>
            </li>
          </ul>
          <Typography sx={{ marginTop: '10px' }} variant="body2">En particular este sitio utiliza Google Analytics, un servicio de web analítico prestado por Google, Inc. con domicilio en los Estados Unidos con sede central en 1600 Amphitheatre Parkway, Montain View, California 94043. Para la prestación de estos servicios, estos utilizan cookies que recopilan información, incluida la dirección IP del usuario, que será trasmitida, tratada y almacenada por Google en los términos fijados en la Web Google. Com. Incluyendo la posible transmisión de dicha información a terceros por razones de exigencia legal o cuando dichos terceros procesen la información por cuenta de Google.</Typography>
          <ul>
            <li>
              <Typography variant="body2">Cookie: _ga</Typography></li>
            <ul>
              <li><Typography variant="body2">Duración: 2 años</Typography></li>
              <li><Typography variant="body2">Tipo: Análisis</Typography></li>
              <li><Typography variant="body2">Propósito: Cookie de analítica, Cookie generada por Google Analytics, en la que se almacenan datos de los usuarios de la web. Su finalidad es medir y elaborar estadísticas del uso en la web.</Typography></li>
              <li><Typography variant="body2">Proveedores: Google</Typography></li>
            </ul>
            <li>
              <Typography variant="body2">Cookie:_gid</Typography></li>
            <ul>
              <li><Typography variant="body2">Duración:24 horas</Typography></li>
              <li><Typography variant="body2">Tipo: Análisis</Typography></li>
              <li><Typography variant="body2">Propósito: Cookie de analítica, Cookie generada por Google Analytics, en la que se almacenan datos de los usuarios de la web. Su finalidad es medir y elaborar estadísticas del uso en la web.</Typography></li>
              <li><Typography variant="body2">Proveedores: Google</Typography></li>
            </ul>
            <li>
              <Typography variant="body2">Cookie: _gat</Typography></li>
            <ul>
              <li><Typography variant="body2">Duración: 1 minuto</Typography></li>
              <li><Typography variant="body2">Tipo: Análisis</Typography></li>
              <li><Typography variant="body2">Propósito: Cookie generada por Google Analytics, limita el número de solicitudes que se envía a doubleclick, que se usa para generar funciones publicitarias, como los informes de datos demográficos y de intereses que se muestran en google analytics.</Typography></li>
              <li><Typography variant="body2">Proveedores: Google</Typography></li>
            </ul>
          </ul>

          <Typography sx={{ marginTop: '20px' }} variant="h5">IV. ACEPTACIÓN DE LA POLÍTICA DE COOKIES</Typography>
          <Typography sx={{ marginTop: '20px' }} variant="body2">MEJOROFERTALUZYGAS.ES muestra información sobre su Política de cookies en cualquier página web con cada inicio de sesión.</Typography>
          <Typography variant="body2">Ante esta información es posible lleva a cabo las siguientes acciones:</Typography>
          <ul>
            <li><Typography variant="body2">Aceptar cookies: No se volverá a visualizar este aviso al acceder a cualquier página de MEJORTARIFALUZYGAS.ES durante la presente sesión.</Typography></li>
            <li><Typography variant="body2">Cerrar: Se oculta el aviso en la presente página.</Typography></li>
            <li><Typography variant="body2">Modificar su configuración: Podrá obtener más información sobre que son las cookies, conocer la política de cookies de MEJOROFERTALUZYGAS.ES y modificar la configuración de su navegación. Pero esto no evitará que se muestre el aviso sobre cookies a nuevas páginas de MEJOROFERTALUZYGAS.ES</Typography></li>
          </ul>

          <Typography sx={{ marginTop: '20px' }} variant="h5">V. CÓMO MODIFICAR LA CONFIGURACIÓN DE LAS COOKIES</Typography>
          <Typography sx={{ marginTop: '20px' }} variant="body2">Usted puede restringir, bloquear o borrar las cookies de MEJORTARIFALUZYGAS.ES o cualquier otra página web, utilizando su navegador. En cada navegador la configuración es diferente, la función de “Ayuda” le mostrará cómo hacerlo, no obstante, le indicamos los siguientes enlaces directos con información al respecto:</Typography>
          <ul>
            <li><Typography variant="body2"><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></Typography></li>
            <li><Typography variant="body2"><a href="support.mozilla.org/es/kb/Borrar%20cookies">Firefox</a></Typography></li>
            <li><Typography variant="body2"><a href="http://support.google.com/chrome/answer/95647?hl=es">Chrome</a></Typography></li>
            <li><Typography variant="body2"><a href="www.apple.com/es/privacy/use-of-cookies">Safari</a></Typography></li>
          </ul>
        </Container>
      </Stack>
      <Footer />
    </ThemeProvider>
  );
}

export default Cookies;
