import React from 'react';
import { makeStyles, useThemeVariants } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles({
  
  textStack: {
    margin: '0 auto',
    //maxWidth: '460px',
    alignSelf: 'center',
    '@media (max-width:2500px)': {
      padding: '40px',
    },
    '@media (max-width:500px)': {
      padding: '20px',
      backgroundColor: 'var(--colors-primary)',
      '& h1, & h2, & h3, & h5': {
        color: '#FFFFFF',
      },
      '& h5, & h1':{
        fontSize: '1rem',
      },
      '& h3': {
        fontSize: '1.56rem',
      }
    },
  },
  stack: {
    margin: props => props.marginStack,
    '@media (max-width:1500px)': {
      margin: props => props.marginStackReduced,
    },
    '@media (max-width:1300px)': {
      margin: '10% 10% auto 10%'
    },
  },
  stackHeader: {
    '@media (max-width:1300px)': {
      marginTop: '7%'
    },
  },
  button: {
    '&:hover': {
      backgroundColor: 'var(--colors-primary)',
    },
    '@media (max-width:1300px)': {
      marginBottom: '7%',
    },
  },
  a: {
    textDecoration: 'none !important',
  }
});

function HeaderBanner() {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4} className={classes.textStack}>
          <Stack >
            <Typography component="h2" variant="h1" >Sólo este mes</Typography>
            <Typography component="h1" variant="h5" color="#00404D" sx={{paddingTop: '10px', fontWeight:'300'}}><strong>Repsol. La mejor oferta de luz y gas del mercado</strong></Typography>
            <Typography variant="h5" color="#00404D" sx={{paddingTop: '15px', fontWeight:'300'}}><strong>50% de descuento</strong> en luz<br /> 10 horas al día</Typography>
            <Typography variant="h3" sx={{paddingTop: '25px'}}>¡Y llévate hasta<br /> 150€ en carburante!</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={8} sx={{ borderStyle: 'solid', borderColor: {xs: 'var(--colors-separator-mobile)', sm: 'var(--colors-primary)'}, borderWidth:'0', borderLeftWidth: {xs: '0', sm:'135px'}, borderTopWidth: {xs: '40px', sm:'0'}}}>
          <picture>
            <source media="(max-width: 500px)" srcSet={require('../assets/images/banner-home.png')} />
            <source media="(max-width: 1300px)" srcSet={require('../assets/images/banner-home.png')} />
            <img width="100%" height="100%" src={require('../assets/images/banner-home.png')} alt="banner"  style={{display: 'block', position: 'relative', objectFit: 'cover'}} />
          </picture>
        </Grid>
      </Grid>
    </>
  )
}

export default HeaderBanner;
