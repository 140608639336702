import { pushToDataLayerDDI } from "./PostLead";

export const isPhoneValid = (value) => {
  var str = value.toString().replace(/\s/g, "");
  return str.length === 9 && /^[6789]{1}[0-9]{8}$/.test(str);
};

export const isResponsive = (width) => {
  return width <= 640;
};

export const isResponsiveTablet = (width) => {
  return width <= 1116;
};

export const dial = (phone) => {
  window.open(`tel:${phone}`, "_self", "noopener");
  pushToDataLayerDDI(phone);
};
