import * as React from 'react';
import Grid from '@mui/material/Grid';
import ComplexCard from './luz/ComplexCard';

const ComplexCardsGasMobile = () => {
    return (
        <Grid sx={{ marginTop: '50px' }} container spacing={{ xs: 3, lg: 4}} columns={{ xs: 4, sm: 8, md: 12 }}
            justifyContent="center"
            alignItems="stretch">
            <Grid item xs={3}>
                <ComplexCard title="Tarifa Gas +" ctaText="MÁS INFO" descriptionBold1="Precio fijo," description1="sin cortes en el suministro y con la máxima tranquilidad" description2="Paga solo por lo que consumes y disfruta del servicio de" descriptionBold3=" mantenimiento de electrodomésticos GRATIS"/>
            </Grid>
        </Grid>
    );
}

export default ComplexCardsGasMobile;