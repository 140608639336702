import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import C2CDialog from '../dialog/C2CDialog';

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: 'var(--colors-primary)',
    },
    '@media (max-width:1200px)': {
      marginBottom: '7%'
    },
  },
  texth2: {
    '@media (max-width:600px)': {
      fontSize: '1.125rem!important'
    },
  },
  textp: {
    '@media (max-width:600px)': {
      fontSize: '1rem!important'
    },
  }
});

function ComplexCard({ title, ctaText, description }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Card sx={{
        background: '0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 15px #0000000D;',
        borderRadius: '20px',
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
      }}>
        <CardContent sx={{
          padding: '35px',
          display: 'flex',
          flex: '1 0 auto',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}>
          <Typography
            sx={{ lineHeight: '1.3', textAlign: 'center', marginBottom: '10px' }}
            style={{ fontSize: '1.375rem', color: 'var(--colors-text-normal)', fontFamily: 'Roboto,Arial', fontWeight: '700' }}
            variant="h2" className={classes.texth2}
            dangerouslySetInnerHTML={{ __html: title }} />
          <Typography
            sx={{ lineHeight: '1.3', textAlign: 'center', marginBottom: '10px' }}
            style={{ color: 'var(--colors-text-dim)', fontSize: '1.125rem' }}
            variant="body2" className={classes.textp}
            dangerouslySetInnerHTML={{ __html: description }} />


          <Button className={classes.button}
            onClick={() => {
              handleClickOpen();
            }}
            sx={{
              background: 'var(--colors-bg-button)',
              boxShadow: '0px 3px 6px #00000029',
              borderRadius: '5px',
              marginTop: 'auto',
              height: '50px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography sx={{ lineHeight: '1.4rem', textTransform: 'initial'}} color="white" variant="subtitle1">{ctaText}</Typography>
          </Button>
        </CardContent>
      </Card>
    </>
  );
}

export default ComplexCard;
