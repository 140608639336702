import React from 'react';
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import '../animation.css';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  animation: {
    textAlign: 'center',
    marginTop: '-5rem',
    marginBottom: '2rem',
  }
});

function AnimatedLink() {
  const { search } = useLocation();
  const classes = useStyles();
  return (
    <Container className={classes.animation}>
      <div class="logo">
      </div>
      <Link  underline="none" href={`/mejor-oferta/${search}`} variant="body3" color="#051E42" position="relative" zIndex="2">
        DESCUBRE LA MEJOR OFERTA
      </Link>

    </Container>
  )
}

export default AnimatedLink;