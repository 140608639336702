import * as React from 'react';
import Grid from '@mui/material/Grid';
import RoundedPlainCard from './RoundedPlainCard';

const RoundedPlainCardsLuz = () => {
  return (
    <Grid container spacing={{ xs: 2, lg: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}
      justifyContent="center"
      alignItems="stretch">
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../../assets/images/handshake.png')} title="Contratación sencilla y sin papeleos" props={{ marginTop: '0%', marginLeft: '10%', marginLeftMobile: '10%', marginLeftTextMobile: '0%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../../assets/images/lightbulb.png')} title="Energía 100% renovable" props={{ marginTop: '0%', marginLeft: '0', marginLeftMobile: '10%', marginLeftTextMobile: '7%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../../assets/images/mobile.png')} title="APP para ver tu consumo en tiempo real" props={{ marginTop: '0%', marginLeft: '0', marginLeftMobile: '45%', marginRightMobile: '30%', marginLeftTextMobile: '19%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../../assets/images/pig.png')} title="Si encuentras una tarifa más barata, ¡la igualamos!" props={{ marginTop: '0%', marginLeft: '0', marginLeftMobile: '20%', marginLeftTextMobile: '6%' }} />
      </Grid>
    </Grid>
  );
}

export default RoundedPlainCardsLuz;