import * as React from 'react';
import LinkMui from '@mui/material/Link';
import { Link, useLocation } from "react-router-dom";
import Logo from '../assets/images/logo.svg';

const Header = () => {
  const { search } = useLocation();

  return (
    <>
      <LinkMui underline="none">
        <Link to={`/${search}`}>
          <img src={Logo} alt="logo" style={{marginTop: 15, marginBottom: 15, marginLeft: '1%', height: 50}}/>
        </Link> 
      </LinkMui>
    </>
  );
};

export default Header