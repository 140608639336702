import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const useStyles = makeStyles(() => ({
  text: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '100%',
    animation: "$move 15s linear infinite",
  },
  "@keyframes move": {
    "0%": {
      transform: "translateX(70vw)"
    },
    "100%": {
      transform: "translateX(-77vw)"
    }
  },
  subtitle1: {
    fontSize: '1.1875rem',
    fontFamily: 'Galano Grotesque Bold, Arial',
    lineHeight: '1.75',
    '@media (min-width:1200px)': {
      fontSize: '1.4286rem'
    },
    '@media (min-width:900px)': {
      fontSize: '1.2857rem'
    },
  },
}));

const Subheader = ({ text }) => {
  const classes = useStyles();

  return (
    <Box sx={{ backgroundColor: '#051E42', textAlign: 'center', overflow: 'hidden' }}>
      <Typography variant="h2" color="white" className={[classes.text , classes.subtitle1]}>{text}</Typography>
    </Box>
  );
};

export default Subheader;