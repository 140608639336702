import { slide as Menu } from 'react-burger-menu'
import { useLocation } from "react-router-dom";
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import HeaderDDI from './HeaderDDI';

var styles = {
  bmBurgerButton: {
    position: 'relative',
    width: '30px',
    height: '24px',
    right: '0px',
    top: '30px',
    marginRight: '10px',
    marginLeft: '10px'
  },
  bmBurgerBars: {
    background: '#373a47',
    height: '13%'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#000000'
  },
  bmMenuWrap: {
    position: 'fixed',
    top: '0',
    height: '220px',
    width: '100%'
  },
  bmMenu: {
    textAlign: 'center',
    background: '#FFFFFF',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad'
  },
  bmOverlay: {
    background: '#FFFFFF',
    opacity: '0',
  }
}
function HamburgerMenu() {
  const { search } = useLocation();

  return (
    <Menu right styles={styles}>
      <Stack>
        <Container disableGutters>
          <HeaderDDI link={`/${search}`} />
        </Container>
        <Container>
          <Link underline="none" href={`/luz${search}`} variant="body3" color="#051E42">
            LUZ
          </Link>
        </Container>
        <Container>
          <Link underline="none" href={`/luzygas${search}`} variant="body3" color="#051E42">
            LUZ Y GAS
          </Link>
        </Container>
        <Container>
          <Link underline="none" href={`/gas${search}`} variant="body3" color="#051E42">
            GAS
          </Link>
        </Container>
      </Stack>
    </Menu >
  );
}

export default HamburgerMenu;