import * as React from 'react';
import Grid from '@mui/material/Grid';
import RoundedPlainCard from './luz/RoundedPlainCard';

const RoundedPlainCardsGas = () => {
  return (
    <Grid container spacing={{ xs: 2, lg: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}
      justifyContent="center"
      alignItems="stretch">
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../assets/images/mobile-dialog.png')} title="Contratación sencilla y sin papeleos ni cortes de suministro" props={{ marginTop: '10%', marginLeft: '10%', marginLeftMobile: '10%', marginLeftTextMobile: '0%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../assets/images/mobile-charts.png')} title="APP para ver tu consumo en tiempo real" props={{ marginTop: '10%', marginLeft: '10%', marginLeftMobile: '15%', marginLeftTextMobile: '7%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../assets/images/bill-cut.png')} title="Descuentos en carburantes y premios con nuestra APP" props={{ marginTop: '0%', marginLeft: '10%', marginLeftMobile: '15%', marginRightMobile: '30%', marginLeftTextMobile: '12%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../assets/images/pig.png')} title="Si encuentras una tarifa más barata, ¡la igualamos!" props={{ marginTop: '0%', marginLeft: '10%', marginLeftMobile: '15%', marginLeftTextMobile: '6%' }} />
      </Grid>
    </Grid>
  );
}

export default RoundedPlainCardsGas;