import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import HeaderThinBanner from '../banner/HeaderThinBanner';
import Footer from '../footer/Footer';
import RoundedPlainCardsContratar from '../card/luz/RoundedPlainCardsContratar';
import HeadingLuz from '../heading/HeadingLuz';
import Subheader from '../heading/Subheader';
import ComplexCardsContratar from '../card/ComplexCardsContratar';
import QuestionsContratar from '../questions/QuestionsContratar';
import BannerImageForm from '../banner/BannerImageFormContratar';
import BannerImageMobile from '../banner/BannerImageMobile';
import StickyContainer from '../container/StickyContainer';
import StickyDDIMobile from '../button/StickyDDIMobile';
import MobileAnchor from '../button/MobileAnchor';
import StickyC2C from '../button/StickyC2C';

function Contratar() {
  const { search } = useLocation();
  return (
    <>
      <Stack>
        <StickyDDIMobile />
        <StickyC2C />
        <StickyContainer>
          <Container disableGutters>
            <HeaderMenuDDI link={`/${search}`} />
          </Container>
          <Subheader text="-30 CÉNTIMOS POR LITRO EN TODOS TUS REPOSTAJES CON NUESTRA APP" />
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderThinBanner props={{ marginStack: '10% 10% auto 10%', marginStackReduced: '5% 10% auto 10%' }} titleLine1="¿Hablamos?"
            imageMobile={require('../assets/images/bannerContratarMobile.png')}
            imageTablet={require('../assets/images/bannerContratarTablet.jpg')}
            imageDesktop={require('../assets/images/bannerContratarDesktop.png')} />
        </Container>
        <Container>
          <MobileAnchor />
        </Container>
        <Container sx={{ marginTop: '40px' }} maxWidth="xl" disableGutters>
          <RoundedPlainCardsContratar />
          <BannerImageMobile image={require('../assets/images/banner-home-mobile-2.png')} />
        </Container>
        <Container id="tarifas">
          <HeadingLuz text="Contrata la mejor tarifa de energía en 5 minutos ¡Solo este mes!" />
        </Container>
        <Container maxWidth="xl" disableGutters>
          <ComplexCardsContratar />
        </Container>
        <Container maxWidth="false" disableGutters>
          <BannerImageForm imageMobile={require('../assets/images/banner-home-mobile-3.png')} imageDesktop={require('../assets/images/banner-home-3.png')} />
        </Container>
        <Container sx={{ marginBottom: '60px' }}>
          <HeadingLuz text="No queremos que te quedes con dudas, ¡somos la solución!" />
          <QuestionsContratar />
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default Contratar;
