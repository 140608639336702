import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import HeaderThinBanner from '../banner/HeaderThinBanner';
import Footer from '../footer/Footer';
import RoundedPlainCardsLuzYGas from '../card/luz/RoundedPlainCardsLuzYGas';
import HeadingLuz from '../heading/HeadingLuz';
import Heading from '../heading/Heading';
import Subheader from '../heading/Subheader';
import ComplexCardsLuzYGas from '../card/luz/ComplexCardsLuzYGas';
import QuestionsLuzYGas from '../questions/QuestionsLuzYGas';
import BannerImage from '../banner/BannerImage';
import BannerImageMobile from '../banner/BannerImageMobile';
import StickyContainer from '../container/StickyContainer';
import StickyDDIMobile from '../button/StickyDDIMobile';
import StickyC2C from '../button/StickyC2C';
import MobileAnchor from '../button/MobileAnchor';
import Helmet from "react-helmet"
import snippet from '../assets/data/richSnippet.json';

function LuzYGas() {
  const { search } = useLocation();

  return (
    <>
      <Helmet>
        <title>Contrata la mejor tarifa Luz y Gas para ahorrar | Repsol</title>
        <meta name="description" content="Contrata la mejor tarifa de luz y gas con Repsol. Garantizamos el mejor precio. Te asesoramos con la tarifa que mejor se adapta a tus necesidades. Ahorra con tu nueva tarifa de Luz y Gas de Repsol"/>
        <script type="application/ld+json">
          {JSON.stringify(snippet.luzygas)}
        </script>
      </Helmet>
      <Stack>
        <StickyDDIMobile />
        <StickyC2C />
        <StickyContainer>
          <Container disableGutters>
            <HeaderMenuDDI link={`/${search}`} />
          </Container>
          <Subheader text="-30 CÉNTIMOS POR LITRO EN TODOS TUS REPOSTAJES CON NUESTRA APP" />
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderThinBanner props={{ marginStack: '10% 10% auto 10%', marginStackReduced: '1% 7% auto 7%' }} titleLine1="¿Hablamos?"
            imageMobile={require('../assets/images/banner-luzygas-mobile.png')}
            imageTablet={require('../assets/images/banner-luzygas-tablet.png')}
            imageDesktop={require('../assets/images/banner-luzygas.png')} />
        </Container>
        <Container>
          <MobileAnchor />
        </Container>
        <Container sx={{ marginTop: '40px' }} maxWidth="xl" disableGutters>
          <RoundedPlainCardsLuzYGas />
          <BannerImageMobile image={require('../assets/images/banner-luzygas-mobile-2.png')} />
        </Container>
        <Container id="tarifas">
          <Heading text="Hay muchas formas de ahorrar en tu factura de luz y gas, ¡elige la tuya!" />
        </Container>
        <Container maxWidth="xl" disableGutters>
          <ComplexCardsLuzYGas />
        </Container>
        <Container maxWidth="false" disableGutters>
          <BannerImage imageMobile={require('../assets/images/banner-luzygas-mobile-3.png')} imageDesktop={require('../assets/images/banner-luzygas-2.png')} />
        </Container>
        <Container sx={{marginBottom: '60px'}}>
          <HeadingLuz text="No queremos que te quedes con dudas, ¡somos la solución!" />
          <QuestionsLuzYGas />
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default LuzYGas;
