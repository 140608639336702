import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  subtitle1: {
    fontSize: '1.1875rem',
    fontFamily: 'Galano Grotesque Bold, Arial',
    '@media (min-width:1200px)': {
      fontSize: '1.4286rem'
    },
    '@media (min-width:900px)': {
      fontSize: '1.2857rem'
    },
  },
});

function Question({ question, answer }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return(
    <>
      <Grid item xs={12} sm container  onClick={() => setOpen(!open)}>
        <Grid item xs>
          <Typography className={classes.subtitle1} variant="h3" sx={{ display: 'inline-block' }} color="#051E42">{question}</Typography>
        </Grid>
        <Grid item>
          <IconButton size="small" sx={{ background: '#FF6200 0% 0% no-repeat padding-box',
            boxShadow: 'inset 2px 2px 10px #FFFFFFBC, 0px 3px 6px #00000029',
            borderRadius: '10px'
            }}
            aria-label="show">
            {!open && <ArrowDropDownIcon style={{ fill: "white"}} />}
            {open && <ArrowDropUpIcon style={{ fill: "white"}} />}
          </IconButton>
        </Grid>
      </Grid>
      {open && <Typography variant="body2" color="#051E42">{answer}</Typography>}
    </>
  )
}

export default Question;