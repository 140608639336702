import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import HeaderBanner from '../banner/HeaderBanner';
import Footer from '../footer/Footer';
import RoundedPlainCardsBanner from '../card/RoundedPlainCardsBanner';
import Heading from '../heading/Heading';
import ComplexCardsBanner from '../card/ComplexCardsBanner';
import QuestionsHome from '../questions/QuestionsHome';
import StickyContainer from '../container/StickyContainer';
import StickyForm from "../button/StickyForm";
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import '../App.css';
import Helmet from "react-helmet"
import snippet from '../../assets/data/richSnippet.json';

let themeFlash = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial',
    color: '#00404D',
    h1: {
      color: 'var(--colors-primary)',
      fontSize: '79px',
      fontFamily: 'Tahoma, Arial',
    },
    h2: {
      color: 'var(--colors-primary)',
      fontSize: '60px',
      fontFamily: 'Tahoma, Arial',
    },
    h3: {
      color: 'var(--colors-primary)',
      fontSize: '45px',
      fontFamily: 'Tahoma, Arial',
    },
    h4: {
    },
    h5: {
    },
    subtitle1: {
    },
    subtitle2: {
    },
    body1: {
    },
    body2: {
    },
    body3: {
    },
    body4: {
    },
    body5: {
    }
  },
});

themeFlash = responsiveFontSizes(themeFlash);

function Home() {
  const { search } = useLocation();
  return (
    <>
      <ThemeProvider theme={themeFlash}>
        <CssBaseline />
        <Helmet>
          <title>Contrata luz y gas con Repsol | Empieza a ahorrar </title>
          <meta name="description" content="Las tarifas de luz y gas con Repsol con las que ahorrar. Contrata luz y gas con Repsol y consigue 50% de descuento en luz 10 horas al día ✅ Hasta 150 € en carburante ✅"/>
          <script type="application/ld+json">
            {JSON.stringify(snippet.mejorOferta)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(snippet.all)}
          </script>
        </Helmet>
        <Stack>
          <StickyForm />
          <StickyContainer>
            <Container maxWidth="false" disableGutters>
              <HeaderMenuDDI link={`/${search}`} />
            </Container>
          </StickyContainer>
          <Container maxWidth="false" disableGutters>
            <HeaderBanner />
          </Container>
          <Container maxWidth="false" disableGutters sx={{marginTop: {xs: '10px', sm:'90px'}}}>
            <ComplexCardsBanner />
          </Container>
          <Container sx={{ marginTop: {xs: '10px', sm:'90px'} }} maxWidth="false" disableGutters>
            <RoundedPlainCardsBanner />
          </Container>
          <Container maxWidth="xl" sx={{ marginBottom: '60px' }}>
            <Heading text="Resolvemos tus dudas" />
            <QuestionsHome />
          </Container>
        </Stack>
        <Footer />

      </ThemeProvider>    
    </>
  );
}

export default Home;
