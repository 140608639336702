import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import LinkMui from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import C2CDialog from "../dialog/C2CDialog";
import { dial, isResponsive } from "../utils/Functions";
import { getDDI } from "../utils/GetDDI";
import useWindowSize from "../utils/WindowSize";

const useStyles = makeStyles({
  button: {
    paddingLeft: "15px",
    paddingRight: "15px",
    "&:hover": {
      backgroundColor: "#F39D0A",
    },
  },
  logo: {
    marginTop: "20px",
    marginBottom: "15px",
    height: "35px",
    marginLeft: "3%",
  },
  right: {
    marginLeft: "auto",
    marginRight: "40px",
  },
});

const HeaderDDI = ({ link }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [ddi, setDDI] = useState(null);
  const [width] = useWindowSize();

  getDDI().then((result) => {
    setDDI(result);
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog open={open} onClose={handleClose} />
      <Grid container alignItems="start">
        <Grid item>
          <LinkMui underline="none">
            <Link to={link}>
              <img
                width="181px"
                height="470px"
                src={Logo}
                alt="logo"
                className={classes.logo}
              />
            </Link>
          </LinkMui>
        </Grid>
        <Grid item className={classes.right}>
          <Button
            className={classes.button}
            onClick={() => {
              if (isResponsive(width)) {
                dial(ddi);
              } else {
                handleClickOpen();
              }
            }}
            sx={{
              background: "#FF6200 0% 0% no-repeat padding-box",
              boxShadow: "inset 2px 2px 10px #FFFFFFBC, 0px 3px 6px #00000029",
              borderRadius: "10px",
              marginTop: "13px",
              height: "45px",
              width: "100%",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <Stack sx={{ marginTop: "4px" }} spacing={-1.5}>
              <Typography
                color="white"
                variant="body5"
                sx={{ fontSize: "14px" }}
              >
                Llama ahora
              </Typography>
              <Typography color="white" variant="subtitle1">
                {ddi}
              </Typography>
            </Stack>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderDDI;
