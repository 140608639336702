import React, { useState } from 'react';
import { Link } from "react-router-dom";
import BysidecarLogo from '../assets/images/bysidecar-logo-white.svg';
import BysidecarLogoMobile from '../assets/images/bysidecar_logo_header_white.svg';
import RepsolLogo from '../assets/images/repsol-logo.svg';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { getDDI } from '../utils/GetDDI';
import { Linking } from 'react-native';
import useWindowSize from '../utils/WindowSize';
import C2CDialog from '../dialog/C2CDialog';
import { isResponsive } from '../utils/Functions';

const useStyles = makeStyles({
  header: {
    'boxShadow': '0px 3px 6px #00000029',
  },
  logoContainer: {
    marginTop: '25px',
    marginBottom: '15px',
    marginLeft: '25%',
    '@media (max-width:900px)':{
      padding:'1rem 0rem',
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
    },
    '@media (max-width:500px)':{
      padding:'1rem 0rem',
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
    }
  },
  button: {
    backgroundColor: 'white',
    marginRight: '15%',
    marginLeft: '5%',
    '&:hover': {
      backgroundColor: '#00809A',
    },
    '&:hover span': {
      color: 'white',
    },
    '&:hover p': {
      color: 'white',
    },
    '@media (min-width:900px)': {
      marginRight: '25%',
      marginLeft: '25%',
    },
    '@media (max-width:1200px)': {
      //marginBottom: '7%',
    },
    '@media (max-width:600px)': {
      padding:'1rem 15px',
      width: 'auto',
      margin: '0',
      marginRight: '20px',
      justifyContent: 'center!important',
      alignItems: 'center!important'
    },
  },
  repsolLogo: {
    height: '32px',
    verticalAlign: 'middle',
    '@media (max-width:900px)': {
      //marginTop: '17px',
      width: '100%',
      height: 'auto',
      padding: '10px',
    },
    '@media (max-width:500px)': {
      //marginTop: '17px',
      width: '100%',
      height: 'auto',
      padding: '5px',
    },
  },
  bysidecarLogo: {
    height: '25px',
    width: '150px',
    marginTop: '10px',
    objectFit: 'contain',
    '@media (max-width:900px)': {
      //marginTop: '17px',
      height: 'auto',
      width: '75px',
      marginTop: '5px',
      //padding: '5px',
    },
    '@media (max-width:500px)': {
      //marginTop: '17px',
      height: 'auto',
      width: '75px',
      marginTop: '5px',
      //padding: '5px',
    },
    '& img': {
      width: '110px',
      color:'white',
      '@media (max-width:500px)': {
        width: '75px'
      }
    }
  },
  text1: {
    '@media (max-width:500px)': {
      fontSize: '10px'
    },
  },
  text2: {
    fontSize: '1.5625rem',
    '@media (max-width:500px)': {
      fontSize: '14px'
    },
  }
});

const HeaderMenuDDI = ({ link }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [ddi, setDDI] = useState(null);
  const [width] = useWindowSize();

  getDDI().then((result) => { setDDI(result); });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Grid container className={classes.header}>
        <Grid item xs={4} sm={3} lg={2} alignItems="center" container sx={{justifyContent: {xs: 'center', sm: 'start'}}}>
          <Stack className={classes.logoContainer} spacing={-1.5}>
              <Link to={link}>
                <img src={RepsolLogo} alt="logo" className={classes.repsolLogo}/>
              </Link>
          </Stack>
        </Grid>
        <Grid item xs={3} sm={6} lg={7} alignItems="center" container sx={{justifyContent: {xs: 'center', sm: 'start'},  backgroundColor:'var(--colors-primary)'}}>
          <Stack sx={{marginLeft:'2%'}} >
            <picture className={classes.bysidecarLogo} alt="bysidecar logo">
              <source media="(max-width: 600px)" srcSet={BysidecarLogoMobile} />
              <img src={BysidecarLogo} alt="bysidecar logo" />
            </picture>
            <Typography sx={{display: {xs: 'none', sm: 'block'}, fontSize: '0.7rem'}} color="white" variant="subtitle1">colaborador oficial</Typography>
          </Stack>
        </Grid>
        <Grid item xs={5} sm={3} lg={3}  container alignItems={'center'} justifyContent={'end'} backgroundColor={'var(--colors-primary)'} >
          <Button className={classes.button}
            onClick={() => {
              if (isResponsive(width)) {
                Linking.openURL(`tel:${ddi}`);
              } else {
                handleClickOpen();
              }
            }}
            sx={{
              background: '#FFFFF 0% 0% no-repeat padding-box',
              borderRadius: '5px',
              //marginTop: '13px',
              height: {xs: '40px', sm:'55px'},
              width: '110%',
              paddingLeft: '40px',
              paddingRight: '40px',
              textTransform: 'none',
            }}>
            <Stack spacing={-.01}>
              <Typography color="var(--colors-primary)" variant="buttonTextDDI" className={classes.text1} sx={{lineHeight: {xs:'16px', sm: '24px'}}}>llama ahora</Typography>
              <Typography color="var(--colors-primary)" className={classes.text2} sx={{lineHeight: {xs:'18px', sm: '1.5625rem'}}} >{ddi}</Typography>
            </Stack>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderMenuDDI;
