import * as React from 'react';
import Grid from '@mui/material/Grid';
import ComplexCard from './luz/ComplexCard';

const ComplexCardsHome = () => {
    return (
        <Grid sx={{ marginTop: '50px' }} container spacing={{ xs: 3, lg: 4}} columns={{ xs: 4, sm: 8, md: 12 }}
            justifyContent="center"
            alignItems="stretch">
            <Grid item xs={3}>
                <ComplexCard title="Tu luz a precio fijo"
                ctaText="¡MÁS INFO!"
                descriptionBold1="¡Coste por kWh fijo!"
                description2="Que no te importe si sube la electricidad o a qué hora la usas, paga la luz "
                descriptionBold3="siempre al mismo precio." />
            </Grid>
            <Grid item xs={3}>
                <ComplexCard title="Tarifa plana: sin sorpresas"
                ctaText="¡MÁS INFO!"
                descriptionBold1="Paga todos los meses lo mismo."
                description2="Personalizamos tu cuota y sin regularizaciones a final de año."/>
            </Grid>
            <Grid item xs={3}>
                <ComplexCard title="Las horas que tú quieras al 50%"
                ctaText="¡MÁS INFO!"
                descriptionBold1="¡Disfruta de 300 horas al mes a mitad de precio! "
                description2="La luz al 50% de descuento las 10 horas del día que elijas. "
                descriptionBold3="Y el gas, siempre al mismo precio."/>
            </Grid>
            <Grid item xs={3}>
                <ComplexCard title="Servicio de asistencia, extra en tranquilidad"
                ctaText="¡MÁS INFO!"
                description1="Añade a tu contrato el "
                descriptionBoldInner1="servicio de asistencia "
                descriptionInner1="que cuidará de tu hogar las "
                descriptionBoldEnd1="24 horas del día."
                description2="Urgencias, reparaciones de electrodomésticos, manitas… ¡Todo lo que necesites!"/>
            </Grid>
        </Grid>
    );
}

export default ComplexCardsHome;