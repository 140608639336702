import * as React from 'react';
import Grid from '@mui/material/Grid';
import RoundedPlainCard from './luz/RoundedPlainCard';
import Heading from '../heading/Heading';
import {makeStyles} from "@mui/styles";
const useStyles = makeStyles({
  cardsStack: {
    alignSelf: 'center',
  },
  cardsImages: {
    height: '50px', width: '50px'
  },
});
const RoundedPlainCardsBanner = () => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} sm={6} justifyContent={"center"} style={{display: 'flex'}} sx={{padding: {xs: '0 20px', sm:'0'}}}>
        <div className={classes.cardsStack}>
        <Heading text="Repsol luz y gas, todo ventajas"></Heading>
        <Grid container spacing={{ xs: 2, lg: 4 }} columns={{ xs: 2, sm: 4, md: 4 }}
          justifyContent="center"
          alignItems="center">
          <Grid item sx={{ width: {xs: '100%', sm: '352px'}}}>
            <RoundedPlainCard
              image={require('../assets/images/energy-icon.png')}
              title="Energía 100% renovable" 
              props={{ imageHeight: '60px', imageWidth: '60px',imageWidthMobile:'50px', imageHeightMobile:'50px', marginTop: '0', marginLeft: '0', marginLeftMobile: '0'}} className={classes.cardsImages}/>
          </Grid>
          <Grid item sx={{ width: {xs: '100%', sm: '352px'}}}>
            <RoundedPlainCard
              image={require('../assets/images/thums-up-icon.png')}
              title="Contratación sencilla y rápida, sin papeleos"
              props={{ imageHeight: '60px', imageWidth: '60px', imageWidthMobile:'50px', imageHeightMobile:'50px', marginTop: '0', marginLeft: '0', marginLeftMobile: '0' }} />
          </Grid>
          <Grid item sx={{ width: {xs: '100%', sm: '352px'}}} >
            <RoundedPlainCard
              image={require('../assets/images/mobile-icon.png')}
              title="APP para conocer tu consumo en tiempo real"
              props={{ imageHeight: '60px', imageWidth: '60px', imageWidthMobile:'50px', imageHeightMobile:'50px', marginTop: '0', marginLeft: '0', marginLeftMobile: '0' }} />
          </Grid>
          <Grid item  sx={{ width: {xs: '100%', sm: '352px'}}}>
            <RoundedPlainCard
              image={require('../assets/images/dollar-icon.png')}
              title="Y si recibes una tarifa más económica, ¡te escuchamos!"
              props={{ imageHeight: '60px', imageWidth: '60px', imageWidthMobile:'50px', imageHeightMobile:'50px', marginTop: '0', marginLeft: '0', marginLeftMobile: '0' }} />
          </Grid>
        </Grid>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ marginTop: {xs:'40px', sm:'0'}, borderLeft: {xs:'none', sm: '95px solid var(--colors-primary)'}, borderBottom: {xs: '40px solid var(--colors-primary)', sm:'none'}, height: {xs: '439px', sm:'auto'} }}>
        <picture>
          <source media="(max-width: 500px)" srcSet={require('../assets/images/smiling-girl.png')} />
          <img width="100%" height="100%" src={require('../assets/images/smiling-girl.png')} alt="banner"  style={{display: 'block', position: 'relative', objectFit: 'cover', objectPosition: 'left'}} />
        </picture>
      </Grid>
    </Grid>
  );
}

export default RoundedPlainCardsBanner;
