import * as React from 'react';
import Grid from '@mui/material/Grid';
import RoundedPlainCard from './luz/RoundedPlainCard';

const RoundedPlainCardsHome = () => {
  return (
    <Grid container spacing={{ xs: 2, lg: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}
      justifyContent="center"
      alignItems="stretch">
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../assets/images/stack.png')}
          title="Hasta 150€ de dto en carburante"
          props={{ marginTop: '10%', marginLeft: '10%', marginLeftMobile: '10%', marginTopText: '5%', marginLeftTextMobile: '3%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../assets/images/handshake.png')}
          title="Si encuentras una tarifa más barata, la igualamos"
          props={{ marginTop: '10%', marginLeft: '10%', marginLeftMobile: '15%', marginLeftTextMobile: '2%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../assets/images/mobile-dialog.png')}
          title="Consulta tu consumo en tiempo real y evita sorpresas"
          props={{ marginTop: '10%', marginLeft: '10%', marginLeftMobile: '15%', marginRightMobile: '30%', marginTopText: '5%', marginLeftTextMobile: '4%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../assets/images/pig.png')}
          title="Consigue descuentos y miles de premios con nuestra APP"
          props={{ marginTop: '0%', marginLeft: '10%', marginLeftMobile: '15%', marginTopText: '5%', marginLeftTextMobile: '3%' }} />
      </Grid>
    </Grid>
  );
}

export default RoundedPlainCardsHome;