import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function InfoCard({ title, description }) {
  return (
    <>
      <Card sx={{
        background: '#C9EAF1',
        boxShadow: '0px 0px 15px #0000000D',
        borderRadius: '8px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <CardContent sx={{
          padding: {xs: '25px', sm:'24px 64px'},
          display: 'flex',
          flex: '1 0 auto',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <Typography sx={{ lineHeight: '1.3', textAlign: 'center' }} style={{ fontSize: '24px', fontWeight: 'bold', color: '#00809A' }} variant="h4">
            {title}
          </Typography>
          <Typography sx={{ marginTop: '13px', lineHeight: '1.3' }} style={{ fontSize: '18px', color: '#00809A', textAlign: 'center'  }} variant="body1">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default InfoCard;
