import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';

function CallingDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ background: '#FFFFFF' }}>Bienvenido al servicio de contratación online</DialogTitle>
        <DialogContent sx={{marginTop: '4%', textAlign: 'center'}}>
          <CircularProgress sx={{color: '#FF6200'}} />
          <DialogContentText id="alert-dialog-description">
            <Typography sx={{ marginTop: '20px' }} variant="body2">Uno de nuestros asesores comerciales se pondrá en contacto contigo</Typography>
          </DialogContentText>
        </DialogContent>
    </Dialog>
  );
}

export default CallingDialog;
