import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
      color: 'var(--colors-bg-button)',
      "&$checked": {
        color: 'var(--colors-bg-button)',
      }
    },
    checked: {}
  });

function ColoredCheckbox({ onChange }) {
    const classes = useStyles();

    return (
        <Checkbox classes={{
            root: classes.root,
            checked: classes.checked
          }} onChange={onChange}/>
    )
}

export default ColoredCheckbox;
