import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import HeaderThinBanner from '../banner/HeaderThinBanner';
import Footer from '../footer/Footer';
import RoundedPlainCardsLuz from '../card/luz/RoundedPlainCardsLuz';
import HeadingLuz from '../heading/HeadingLuz';
import Heading from '../heading/Heading';
import Subheader from '../heading/Subheader';
import ComplexCardsLuz from '../card/luz/ComplexCardsLuz';
import QuestionsLuz from '../questions/QuestionsLuz';
import BannerImage from '../banner/BannerImage';
import BannerImageMobile from '../banner/BannerImageMobile';
import StickyContainer from '../container/StickyContainer';
import StickyDDIMobile from '../button/StickyDDIMobile';
import StickyC2C from '../button/StickyC2C';
import MobileAnchor from '../button/MobileAnchor';
import Helmet from "react-helmet"
import snippet from '../assets/data/richSnippet.json';

function Luz() {
  const { search } = useLocation();

  return (
    <>
      <Helmet>
        <title>Contrata la mejor tarifa Luz y ahorra   | Repsol </title>
        <meta name="description" content="Contrata ahora la mejor orferta de luz para tu casa con Repsol. Paga siempre lo mismo con el precio fijo de luz, tarifa plana y el 50% descuento durante 10h o domingos GRATIS. Garantizamos el mejor precio"/>
        <script type="application/ld+json">
          {JSON.stringify(snippet.luz)}
        </script>    
      </Helmet>
      <Stack>
        <StickyDDIMobile />
        <StickyC2C />
        <StickyContainer>
          <Container disableGutters>
            <HeaderMenuDDI link={`/${search}`} />
          </Container>
          <Subheader text="-30 CÉNTIMOS POR LITRO EN TODOS TUS REPOSTAJES CON NUESTRA APP" />
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderThinBanner props={{ marginStack: '8% 10% auto 10%', marginStackReduced: '1% 7% auto 7%' }} titleLine1="¿Hablamos?"
            imageMobile={require('../assets/images/banner-luz-mobile.png')}
            imageTablet={require('../assets/images/banner-luz-tablet.png')}
            imageDesktop={require('../assets/images/banner-luz.png')} />
        </Container>
        <Container>
          <MobileAnchor />
        </Container>
        <Container sx={{ marginTop: '40px' }} maxWidth="xl" disableGutters>
          <RoundedPlainCardsLuz />
          <BannerImageMobile image={require('../assets/images/banner-luz-mobile-2.png')} />
        </Container>
        <Container id="tarifas">
          <Heading text="Hay muchas formas de ahorrar en tu factura de luz, ¡elige la tuya!" />
        </Container>
        <Container maxWidth="xl" disableGutters>
          <ComplexCardsLuz />
        </Container>
        <Container maxWidth="false" disableGutters>
          <BannerImage imageMobile={require('../assets/images/banner-luz-mobile-3.png')} imageDesktop={require('../assets/images/banner-luz-2.png')} />
        </Container>
        <Container sx={{marginBottom: '60px'}}>
          <HeadingLuz text="No queremos que te quedes con dudas, ¡somos la solución!" />
          <QuestionsLuz />
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default Luz;
