import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import BysidecarLogo from '../assets/images/bysidecar-logo-white.svg';
import RepsolLogo from '../assets/images/repsol-logo.svg';

const useStyles = makeStyles({
  leftContainer: {
    height: '100%',
    background: 'var(--colors-primary)',
  },
  leftStack: {
    marginTop: '0',
    marginRight: '20px',
    '@media (max-width:600px)': {
      marginTop: '0',
      marginBottom: '5px',
      marginRight: '0',
      textAlign: 'center',
    }
  },
  grid: {
    boxShadow: '0px 3px 6px #00000029',
    height: '100px',
    flexGrow: 1,
    '@media (max-width:600px)': {
      height: 'auto',
      paddingBottom: '120px',
    },
  },
  bysidecarFooterLogo: {
    height: '20px',
    '@media (max-width:600px)': {
      height: '15px'
    }
  },
  repsolFooterLogo:{
    paddingLeft: '20px',
    '@media (max-width:600px)': {
      paddingLeft: '0'
    }
  }
});

function Footer() {
  const classes = useStyles();

  const { search } = useLocation();

  return(
    <Grid
      className={classes.grid}
      sx={{ flexDirection: { xs: "column", md: "row"} }}
      container
      justifyContent="flex-start"
      alignItems={{ xs: "justify", sm: "center"}}
      spacing={1}
      maxWidth="false"
    >
      <Grid container xs={12} sm={2} className={classes.leftContainer} justifyContent={{xs: 'center', sm:'end'}} alignItems={{xs: "justify", sm: "center"}} padding="15px 0">
        <Stack spacing={-1.3} alignItems={{xs: "justify", sm: "flex-end"}} className={classes.leftStack}>
          <Link to={`/${search}`}>
            <img src={BysidecarLogo} alt="logo" className={classes.bysidecarFooterLogo}/>
          </Link>
          <Typography color="white" variant="subtitle2">colaborador oficial</Typography>
        </Stack>
      </Grid>
      <Grid container xs={12} sm={3} sx={{justifyContent: {xs: "center", sm: "start"}}} className={classes.repsolFooterLogo} padding="15px 0" >
        <Link to={`/${search}`}>
          <img src={RepsolLogo} alt="logo"/>
        </Link>
      </Grid>
      <Grid item xs={12} sm={7} sx={{fontWeight: 300}}>
        <Grid container spacing={{sm:4}} justifyContent="flex-end" sx={{ textAlign: {xs: 'center', sm:'left'},  flexDirection: {xs: 'column', sm:'row'}, paddingRight: {xs: '0', sm:'20px'} }} >
          <Grid item sx={{ order: {xs: '10', sm: '0'}, marginTop: {xs: '42px', sm: '0'}}}>
            <Typography color="#464646" variant="subtitle2" sx={{fontWeight: 300}}>© 2022 Mejortarifaluzygas. All Rights Reserved.</Typography>
          </Grid>
          <Grid item xs="auto" sx={{ marginTop: { xs: '20px', sm: '20px', md: '0px'} }}>
            <Link to={`/cookies${search}`} target="_blank" style={{ textDecoration: 'none' }}>
                <Typography color="#464646" variant="subtitle2" sx={{fontWeight: 300}}>Política de cookies</Typography>
            </Link>
          </Grid>
          <Grid item xs="auto" sx={{ marginTop: { xs: '20px', sm: '0px'} }}>
            <Link to={`/privacidad${search}`} target="_blank" style={{ textDecoration: 'none' }}>
              <Typography color="#464646" variant="subtitle2" sx={{fontWeight: 300}}>Política de privacidad</Typography>
            </Link>
          </Grid>
          <Grid item xs="auto" sx={{ marginTop: { xs: '20px', sm:'0px'} }}>
            <Link to={`/legal${search}`} target="_blank" style={{ textDecoration: 'none' }}>
              <Typography color="#464646" variant="subtitle2" sx={{fontWeight: 300}}>Información legal</Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Footer;
