import './App.css';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import GalanoGrotesqueLight from './assets/fonts/galano-grotesque/GalanoGrotesque-Light.woff2';
import GalanoGrotesqueBold from './assets/fonts/galano-grotesque/GalanoGrotesque-Bold.woff2';
import Tarifas from './pages/Tarifas';
import Luz from './pages/Luz';
import Gas from './pages/Gas';
import LuzYGas from './pages/LuzYGas';
import Home from './pages/Home';
import Contratar from './pages/Contratar';
import Cookies from './pages/Cookies';
import Privacy from './pages/Privacy';
import Homeflash from '../src/mejoroferta/pages/Home';
import Legal from './pages/Legal';
import { Routes, Route, useLocation } from "react-router-dom";
import Helmet from "react-helmet"
import snippet from './assets/data/richSnippet.json';



let theme = createTheme({
  typography: {
    fontFamily: 'Galano Grotesque, Arial',
    color: '#051E42',
    h1: {
      fontSize: '32px',
      fontFamily: 'Galano Grotesque Bold, Arial',
    },
    h2: {
      fontSize: '32px',
      fontFamily: 'Galano Grotesque Bold, Arial',
    },
    h3: {
      fontSize: '35px',
      fontFamily: 'Galano Grotesque Bold, Arial',
    },
    h4: {
      fontFamily: 'Galano Grotesque Bold, Arial',
    },
    subtitle1: {
      fontSize: '22px',
      fontFamily: 'Galano Grotesque Bold, Arial',
    },
    subtitle2: {
      fontSize: '12px',
    },
    body1: {
      fontSize: '25px',
    },
    body2: {
      fontSize: '18px',
    },
    body3: {
      fontSize: '18px',
      fontFamily: 'Galano Grotesque Bold, Arial',
    },
    body4: {
      fontSize: '16px',
      fontFamily: 'Galano Grotesque Regular, Arial',
    },
    body5: {
      fontSize: '15px',
      fontFamily: 'Galano Grotesque Bold, Arial',
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Galano Grotesque';
          font-style: normal;
          font-display: swap;
          src: local('Galano Grotesque'), local('Galano Grotesque'), url(${GalanoGrotesqueLight}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Galano Grotesque Bold';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Galano Grotesque Bold'), local('Galano Grotesque Bold'), url(${GalanoGrotesqueBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  const site = window.location.href;
  const CURRENT_URL = site
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="tarifas" element={<Tarifas />} />
        <Route path="luz" element={<Luz />} />
        <Route path="gas" element={<Gas />} />
        <Route path="luzygas" element={<LuzYGas />} />
        <Route path="cookies" element={<Cookies />} />
        <Route path="privacidad" element={<Privacy />} />
        <Route path="legal" element={<Legal />} />
        <Route path="contratar" element={<Contratar />} />
      </Routes>
      <Helmet>
        <link rel="canonical" href={CURRENT_URL} />
        <script type="application/ld+json">
          {JSON.stringify(snippet.all)}
        </script>
      </Helmet>
    </ThemeProvider>
  );
}

export default App;
