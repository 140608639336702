import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LinkMui from '@mui/material/Link';
import Button from '@mui/material/Button';
import { Link, useLocation } from "react-router-dom";
import { isPhoneValid } from '../utils/Functions';
import CallingDialog from '../dialog/CallingDialog';
import { launchC2C } from '../utils/PostLead';

const useStyles = makeStyles({
  form: {
    position: 'absolute',
    top: '0',
    left: '0',
    maxWidth: '500px',
    height: '80%',
    marginTop: '3%',
    marginLeft: '15%',
    marginRight: '5%',
    '@media (max-width:600px)': {
      marginTop: '13%',
      marginLeft: '5%',
    },
    '@media (max-width:800px)': {
      marginTop: '10%',
    },
  },
  textField: {
    width: '100%',
    marginTop: '10px',
    borderRadius: '10px',
    '& .MuiOutlinedInput-input': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      color: 'white',
    }
  },
  button: {
    '&:hover': {
      backgroundColor: '#051E42',
    },
  },
});

function BannerImage({ imageDesktop, imageMobile }) {
  const classes = useStyles();
  const { search } = useLocation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);

  const handleCloseCallingDialog = () => {
    setButtonClicked(false);
  };

  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  }

  const handleTextFieldChange = (event) => {
    setPhoneNumber(event.target.value);
  }

  const handleClickOpen = () => {
    setIsOpenCallingDialog(true);
    setTimeout(() => {
      setIsOpenCallingDialog(false);
      handleCloseCallingDialog();
    }, 3000);
  };

  return (
    <>
      <CallingDialog
        open={isOpenCallingDialog}
        onClose={handleCloseCallingDialog}
      />
      <Box sx={{ position: 'relative', marginTop: '70px' }}>
        <picture>
          <source media="(max-width: 500px)" srcSet={imageMobile} />
          <img style={{ objectFit: 'cover', width: '100%', height: '550px' }} src={imageDesktop} alt="banner"/>
        </picture>
        <Box className={classes.form}>
          <Typography variant="h3" color="white">¿Buscas la tarifa que se adapte a tus necesidades?</Typography>
          <Typography variant="body2" color="white" sx={{marginTop: '10px'}}>No somos una comercializadora más, personalizamos cada uno de nuestros productos a tus necesidades. Nos importa tu ahorro.</Typography>
          <Typography variant="body2" color="white" sx={{marginTop: '20px'}}>Indícanos tu número de teléfono y buscamos la mejor tarifa para ti.</Typography>
          <TextField
            onChange={handleTextFieldChange}
            className={classes.textField}
            label={<Typography sx={{ marginTop: '6px' }} variant="body2" color="white">Escribe tu teléfono</Typography>} variant="outlined" />
          <FormControlLabel
            sx={{ marginTop: '5px', marginBottom: '5px' }}
            control={<Checkbox className={classes.checkbox} onChange={handleCheckboxChange} />}
            label={<Typography
              variant="subtitle2" color="white" sx={{lineHeight: '1rem'}}>He leído y acepto la
              <LinkMui underline="none" sx={{ textDecoration: 'none' }}>
                <Link to={`/privacidad${search}`} style={{ textDecoration: 'none' }}> política de privacidad </Link>
              </LinkMui>
              y consiento el tratamiento de mis datos personales para todas las finalidades contenidas en la misma</Typography>
            } />
          {!checked && buttonClicked && <Typography variant="subtitle2" color="white">Debes aceptar la política de privacidad</Typography>}
          {checked && buttonClicked && !isPhoneValid(phoneNumber) && <Typography variant="subtitle2" color="white">El número de teléfono no es válido</Typography>}
          <Button className={classes.button}
            onClick={() => {
              setButtonClicked(true);
              if (checked && isPhoneValid(phoneNumber)) {
                launchC2C(phoneNumber);
                handleClickOpen();
              }
            }}
            sx={{
              background: '#051E42 0% 0% no-repeat padding-box',
              boxShadow: 'inset 2px 2px 10px #FFFFFFBC, 0px 3px 6px #00000029',
              borderRadius: '10px',
              height: '75px',
              width: '100%'
            }}>
            <Typography color="white" variant="subtitle1">¡TE LLAMAMOS!</Typography>
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default BannerImage;