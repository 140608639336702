import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import C2CDialog from '../../dialog/C2CDialog';

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: '#F39D0A',
    },
    '@media (max-width:1200px)': {
      marginBottom: '7%'
    },
  },
});

function ComplexCard({ title, ctaText, descriptionBold1, description1, descriptionBoldInner1, descriptionInner1, descriptionBoldEnd1, descriptionBold2, description2, descriptionBold3, description3 }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Card sx={{
        maxWidth: 320,
        background: '0% 0% no-repeat padding-box',
        boxShadow: 'inset 2px 2px 25px #FFFFFF, 0px 3px 6px #00000029',
        borderRadius: '20px',
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
      }}>
        <CardContent sx={{
          padding: '20px',
          display: 'flex',
          flex: '1 0 auto',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <Typography sx={{ lineHeight: '1.3' }} style={{ fontSize: '27px', color: '#FF6200' }} variant="h2">
            {title}
          </Typography>
          <Typography sx={{ marginTop: '13px', lineHeight: '1.3' }} style={{ fontSize: '18px', color: '#051E42' }} variant="h4">
            {descriptionBold1}
          </Typography>
          <Typography sx={{ lineHeight: '1.3' }} style={{ color: '#051E42' }} variant="body2">
            {description1}
            <Typography display="inline" sx={{ lineHeight: '1.3', paddingBottom: '30px' }} style={{ fontSize: '18px', color: '#051E42' }} variant="h4">
              {descriptionBoldInner1}
            </Typography>
            <Typography display="inline" sx={{ lineHeight: '1.3' }} style={{ color: '#051E42' }} variant="body2">
              {descriptionInner1}
            </Typography>
            <Typography display="inline" sx={{ lineHeight: '1.3', paddingBottom: '30px' }} style={{ fontSize: '18px', color: '#051E42' }} variant="h4">
              {descriptionBoldEnd1}
            </Typography>
          </Typography>
          <Typography sx={{ marginTop: '25px', lineHeight: '1.3' }} style={{ fontSize: '18px', color: '#051E42' }} variant="h4">
            {descriptionBold2}
          </Typography>
          <Typography sx={{ lineHeight: '1.3', paddingBottom: '30px' }} style={{ color: '#051E42' }} variant="body2">
            {description2}
            <Typography display="inline" sx={{ lineHeight: '1.3', paddingBottom: '30px' }} style={{ fontSize: '18px', color: '#051E42' }} variant="h4">
              {descriptionBold3}
            </Typography>
            {description3}
          </Typography>

          <Button className={classes.button}
            onClick={() => {
              handleClickOpen();
            }}
            sx={{
              background: '#FF6200 0% 0% no-repeat padding-box',
              boxShadow: 'inset 2px 2px 10px #FFFFFFBC, 0px 3px 6px #00000029',
              borderRadius: '10px',
              marginTop: 'auto',
              height: '60px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography sx={{ lineHeight: '1.4rem' }} color="white" variant="subtitle1">{ctaText}</Typography>
          </Button>
        </CardContent>
      </Card>
    </>
  );
}

export default ComplexCard;