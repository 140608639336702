import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  typography: {
    body2: {
      fontSize: '0.85rem',
    },
  },
});

theme = responsiveFontSizes(theme);
function Question({ question, answer }) {
  const [open, setOpen] = useState(false);

  return(
    <>
      <ThemeProvider theme={theme}>
        <Grid item xs={12} sm container  onClick={() => setOpen(!open)}>
          <Grid item xs>
            <Typography variant="subtitle1" sx={{ display: 'inline-block', fontWeight: '300', fontSize: {xs: '16px', sm:'20px'}}} color="#464646">{question}</Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" aria-label="show">
              {!open && <KeyboardArrowDownIcon style={{ fill: "#00809A", width: '32px', height:'auto'}} />}
              {open && <KeyboardArrowUpIcon style={{ fill: "#00809A", width: '32px', height:'auto'}} />}
            </IconButton>
          </Grid>
        </Grid>
        {open && <Typography variant="body2" color="#464646">{answer}</Typography>}
      </ThemeProvider>
    </>
  )
}

export default Question;
