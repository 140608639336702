import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import HeaderThinBanner from '../banner/HeaderThinBanner';
import Footer from '../footer/Footer';
import RoundedPlainCardsGas from '../card/RoundedPlainCardsGas';
import HeadingLuz from '../heading/HeadingLuz';
import Subheader from '../heading/Subheader';
import QuestionsGas from '../questions/QuestionsGas';
import BannerImage from '../banner/BannerImage';
import ComplexCardsGas from '../card/ComplexCardsGas';
import useWindowSize from '../utils/WindowSize';
import StickyContainer from '../container/StickyContainer';
import { isResponsive } from '../utils/Functions';
import StickyDDIMobile from '../button/StickyDDIMobile';
import StickyC2C from '../button/StickyC2C';
import MobileAnchor from '../button/MobileAnchor';
import Helmet from "react-helmet"
import snippet from '../assets/data/richSnippet.json';

function Gas() {
  const [width] = useWindowSize();
  const { search } = useLocation();

  return (
    <>
      <Helmet>
        <title>Contrata la mejor tarifa Gas | Repsol</title>
        <meta name="description" content="Contrata la mejor tarifa de Gas con Repsol y empieza a ahorrar. Energía 100% renovable para tu hogar al mejor precio. Controla tu consumo en tiempo real con la app y GRATIS el mantenimiento de electrodomésticos"/>
        <script type="application/ld+json">
          {JSON.stringify(snippet.gas)}
        </script>
      </Helmet>
      <Stack>
        <StickyDDIMobile />
        <StickyC2C />
        <StickyContainer>
          <Container disableGutters>
            <HeaderMenuDDI link={`/${search}`} />
          </Container>
          <Subheader text="-30 CÉNTIMOS POR LITRO EN TODOS TUS REPOSTAJES CON NUESTRA APP" />
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderThinBanner props={{ marginStack: '8% 10% auto 10%', marginStackReduced: '1% 7% auto 7%' }} titleLine1="¿Hablamos?"
            imageMobile={require('../assets/images/banner-gas-mobile.png')}
            imageTablet={require('../assets/images/banner-gas-tablet.png')}
            imageDesktop={require('../assets/images/banner-gas.png')} />
        </Container>
        <Container>
          <MobileAnchor />
        </Container>
        <Container sx={{ marginTop: '40px' }} maxWidth="xl" disableGutters>
          <RoundedPlainCardsGas />
        </Container>
        {isResponsive(width) &&
          <>
            <Container id="tarifas">
              <HeadingLuz text="Es el momento de empezar a ahorrar" />
            </Container>
            <Container maxWidth="xl" disableGutters>
              <ComplexCardsGas />
            </Container>
          </>
        }
        <Container maxWidth="false" disableGutters>
          <BannerImage imageMobile={require('../assets/images/banner-gas-mobile-2.png')} imageDesktop={require('../assets/images/banner-gas-2.png')} />
        </Container>
        <Container sx={{marginBottom: '60px'}}>
          <HeadingLuz text="No queremos que te quedes con dudas, ¡somos la solución!" />
          <QuestionsGas />
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default Gas;
