import * as React from 'react';
import Grid from '@mui/material/Grid';
import RoundedPlainCard from './RoundedPlainCard';

const RoundedPlainCardsLuzYGas = () => {
  return (
    <Grid container spacing={{ xs: 2, lg: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}
      justifyContent="center"
      alignItems="stretch">
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../../assets/images/handshake.png')} title="Asesoramiento personalizado de expertos en energía." props={{ marginTop: '0%', marginLeft: '10%', marginLeftMobile: '10%', marginLeftTextMobile: '0%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../../assets/images/lightbulb.png')} title="Energía 100% renovable" props={{ marginTop: '0%', marginLeft: '0', marginLeftMobile: '10%', marginLeftTextMobile: '2%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../../assets/images/mobile.png')} title="Contrata la luz y el gas sin complicaciones ni papeleos." props={{ marginTop: '0%', marginLeft: '0', marginLeftMobile: '45%', marginRightMobile: '30%', marginLeftTextMobile: '8%' }} />
      </Grid>
      <Grid item xs={3}>
        <RoundedPlainCard image={require('../../assets/images/pig.png')} title="Si encuentras una tarifa más barata, ¡la igualamos!" props={{ marginTop: '0%', marginLeft: '0', marginLeftMobile: '20%', marginLeftTextMobile: '1%' }} />
      </Grid>
    </Grid>
  );
}

export default RoundedPlainCardsLuzYGas;