import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import HeaderBanner from '../banner/HeaderBanner';
import Footer from '../footer/Footer';
import RoundedPlainCardsHome from '../card/RoundedPlainCardsHome';
import HeadingLuz from '../heading/HeadingLuz';
import Heading from '../heading/Heading';
import Subheader from '../heading/Subheader';
import ComplexCardsHome from '../card/ComplexCardsHome';
import QuestionsHome from '../questions/QuestionsHome';
import BannerImageForm from '../banner/BannerImageForm';
import BannerImageMobile from '../banner/BannerImageMobile';
import StickyContainer from '../container/StickyContainer';
import StickyDDIMobile from '../button/StickyDDIMobile';
import MobileAnchor from '../button/MobileAnchor';
import StickyC2C from '../button/StickyC2C';
import AnimatedLink from '../card/AnimatedLink';
import Helmet from "react-helmet"
import snippet from '../assets/data/richSnippet.json';

function Home() {
  const { search } = useLocation();

  return (
    <>
      <Helmet>
        <title>Ofertas de luz y gas al mejor precio | Repsol </title>
        <meta name="description" content="Contrata la tarifa de luz o gas que mejor se adapta a tus necesidades y empieza a ahorrar con Repsol. Paga siempre lo mismo con la tarifa plana, consigue descuentos y premios con nuestra app y disfruta de asistencia las 24h del día"/>
        <script type="application/ld+json">
          {JSON.stringify(snippet.home)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(snippet.all)}
        </script>
      </Helmet>
      <Stack>
        <StickyDDIMobile />
        <StickyC2C />
        <StickyContainer>
          <Container disableGutters>
            <HeaderMenuDDI link={`/${search}`} />
          </Container>
          <Subheader text="-30 CÉNTIMOS POR LITRO EN TODOS TUS REPOSTAJES CON NUESTRA APP" />
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderBanner props={{ marginStack: '10% 10% auto 10%', marginStackReduced: '5% 10% auto 10%' }} titleLine1="¿Hablamos?"
            imageMobile={require('../assets/images/banner-home-mobile.png')}
            imageTablet={require('../assets/images/banner-home-tablet.png')}
            imageDesktop={require('../assets/images/banner-home.png')} />
        </Container>
        <Container>
          <MobileAnchor />
        </Container>
        <Container sx={{ marginTop: '40px' }} maxWidth="xl" disableGutters>
          {/* <AnimatedLink /> */}
          <RoundedPlainCardsHome />
          <BannerImageMobile image={require('../assets/images/banner-home-mobile-2.png')} />
        </Container>
        <Container id="tarifas">
          <Heading text="Nuestras mejores tarifas de luz y gas para ahorrar" />
        </Container>
        <Container maxWidth="xl" disableGutters>
          <ComplexCardsHome />
        </Container>
        <Container maxWidth="false" disableGutters>
          <BannerImageForm imageMobile={require('../assets/images/banner-home-mobile-3.png')} imageDesktop={require('../assets/images/banner-home-3.png')} />
        </Container>
        <Container sx={{ marginBottom: '60px' }}>
          <HeadingLuz text="No queremos que te quedes con dudas, ¡somos la solución!" />
          <QuestionsHome />
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default Home;
